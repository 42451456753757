import React, { useState } from "react";

//Images
import img1 from "../../images/de-cloud1.png";
import img2 from "../../images/de-cloud2.png";
import img3 from "../../images/de-cloud3.png";

import "./cloud-components.scss";
import "../../styles/animations.scss";
import { Link } from "gatsby";

import ScrollAnimation from "react-animate-on-scroll";

export default function digitalEnterprise() {
  const [virtInf, setVirtInf] = useState(1);
  const [pce, setPce] = useState(1);
  const [hce, setHce] = useState(1);

  const props = {
    o1: virtInf == 1 ? "active" : "",
    c1: virtInf == 2 ? "active" : "",
    co1: virtInf == 3 ? "active" : "",

    o2: pce == 1 ? "active" : "",
    c2: pce == 2 ? "active" : "",
    co2: pce == 3 ? "active" : "",

    o3: hce == 1 ? "active" : "",
    c3: hce == 2 ? "active" : "",
    co3: hce == 3 ? "active" : "",
  };

  return (
    <>
      <div className="cloud-component-wrapper">
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h1>Digital Enterprise</h1>
            <p>Modern technology solutions for the modern workforce.</p>
          </ScrollAnimation>
          <div className="cloud-component-image-packet">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="cloud-component-image-wrapper">
                <Link to="#anywhere-workspace">
                  <img src={img1} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Anywhere <br /> Workspace
                </h2>
                <p>Support remote work and a distributed force.</p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={0.75}
              delay={100}
            >
              <div className="cloud-component-image-wrapper">
                <Link to="#application-delivery">
                  <img src={img2} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Application <br /> Delivery
                </h2>
                <p>Streamline the software development lifecycle.</p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={0.75}
              delay={200}
            >
              <div className="cloud-component-image-wrapper">
                <Link to="#application-modernisation">
                  <img src={img3} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Application <br /> Modernisation
                </h2>
                <p>
                  Migrate business-critical applications to modern application
                  platforms.
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <section
        className="half"
        id="anywhere-workspace"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
              id="anywhere-workspace"
            >
              ANYWHERE WORKSPACE
            </h2>
            <p style={{ textAlign: "center" }}>
              Support remote work and a distributed force.
            </p>
            <div
              className="cloud-component-sub-wrap"
              style={{ padding: "20px" }}
            >
              <div id="cloud-comp-sub">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <h3
                    id="red-diam"
                    className={props.o1}
                    onClick={() => {
                      setVirtInf(1);
                    }}
                  >
                    OVERVIEW
                  </h3>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={0.75}
                  delay={100}
                >
                  <h3
                    id="black-diam"
                    className={props.c1}
                    onClick={() => {
                      setVirtInf(2);
                    }}
                  >
                    CHALLENGES
                  </h3>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={0.75}
                  delay={200}
                >
                  <h3
                    id="blue-diam"
                    className={props.co1}
                    onClick={() => {
                      setVirtInf(3);
                    }}
                  >
                    CUSTOMER <br /> OUTCOMES
                  </h3>
                </ScrollAnimation>
              </div>
              {virtInf == 1 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3>OVERVIEW:</h3>
                  <p>
                    Supporting remote work and a distributed workforce requires
                    a new approach. Anywhere Workspace is an integrated
                    workforce solution that builds trust for today’s distributed
                    workforce by empowering and enabling employees, reducing
                    silos and operational overhead, and providing broader and
                    more effective security. The ability to deliver Anywhere
                    Workspaces is defined by an IT organisation that can deliver
                    a Unified App Delivery, provide Unified End Point
                    Management, enable Modern Management & Hybrid VDI and
                    establish Enterprise Mobility Management.
                  </p>
                </div>
              ) : (
                <></>
              )}
              {virtInf == 2 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                    CHALLENGES:
                  </h3>
                  <p>
                    • Legacy platforms are costly, slow <br />
                    • Hard to update and maintain apps and platforms <br />
                    • Zero day attack risk <br />
                    • Data leakage <br />
                    • Desktop and application availability <br />•
                    Limited/legacy mobile management and understanding of estate{" "}
                    <br />
                  </p>
                </div>
              ) : (
                <></>
              )}
              {virtInf == 3 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                    CUSTOMER OUTCOMES:
                  </h3>
                  <p>
                    • Reduced risk <br />
                    • Increased agility <br />
                    • Secure mobility <br />
                    • Increased stability <br />
                    • Increased standardisation <br />
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </ScrollAnimation>
        </div>
      </section>

      <section
        id="application-delivery"
        className="half"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
              id="application-delivery"
            >
              APPLICATION DELIVERY
            </h2>
            <p style={{ textAlign: "center" }}>
              Streamline the software development lifecycle.
            </p>
            <div
              className="cloud-component-sub-wrap"
              style={{ padding: "20px" }}
            >
              <div id="cloud-comp-sub">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <h3
                    id="red-diam"
                    className={props.o2}
                    onClick={() => {
                      setPce(1);
                    }}
                  >
                    OVERVIEW
                  </h3>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={0.75}
                  delay={100}
                >
                  <h3
                    id="black-diam"
                    className={props.c2}
                    onClick={() => {
                      setPce(2);
                    }}
                  >
                    CHALLENGES
                  </h3>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={0.75}
                  delay={200}
                >
                  <h3
                    id="blue-diam"
                    className={props.co2}
                    onClick={() => {
                      setPce(3);
                    }}
                  >
                    CUSTOMER <br /> OUTCOMES
                  </h3>
                </ScrollAnimation>
              </div>
              {pce == 1 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3>OVERVIEW:</h3>
                  <p>
                    DevOps is a set of software development practices that
                    combine software development and information technology
                    operations to shorten the development lifecycle. The ability
                    to deliver DevOps is defined by an IT organisation that can
                    enable continuous integration and deployment, rapidly
                    develop and iterate applications and manage everything as
                    code.
                  </p>
                </div>
              ) : (
                <></>
              )}
              {pce == 2 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                    CHALLENGES:
                  </h3>
                  <p>
                    • Lack of a standardised tool chain across the organisation{" "}
                    <br />
                    • Lack of automation across the tool chain <br />• Used to a
                    mini-waterfall approach and not an agile approach <br />•
                    Lack of a shared approach between development and operations{" "}
                    <br />
                    • Difficulty internalising a DevOps way of thinking <br />
                  </p>
                </div>
              ) : (
                <></>
              )}
              {pce == 3 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                    CUSTOMER OUTCOMES:
                  </h3>
                  <p>
                    • Expedient and sustainable delivery of applications <br />
                    • Best of breed platforms embedded into processes and
                    day-to-day activities <br />
                    • End-to-end tool chain integrations and shorter lead times
                    to application value <br />
                    • Foundation for scaling DevOps across the enterprise <br />
                    • Initiating the culture change to support continuous
                    delivery <br />
                    • A path to cloud native applications <br />
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </ScrollAnimation>
        </div>
      </section>

      <section
        id="application-modernisation"
        className="half"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
              id="application-modernisation"
            >
              APPLICATION MODERNISATION
            </h2>
            <p style={{ textAlign: "center" }}>
              Migrate business-critical applications to modern application
              platforms.
            </p>

            <div
              className="cloud-component-sub-wrap"
              style={{ padding: "20px" }}
            >
              <div id="cloud-comp-sub">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <h3
                    id="red-diam"
                    className={props.o3}
                    onClick={() => {
                      setHce(1);
                    }}
                  >
                    OVERVIEW
                  </h3>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={0.75}
                  delay={100}
                >
                  <h3
                    id="black-diam"
                    className={props.c3}
                    onClick={() => {
                      setHce(2);
                    }}
                  >
                    CHALLENGES
                  </h3>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={0.75}
                  delay={200}
                >
                  <h3
                    id="blue-diam"
                    className={props.co3}
                    onClick={() => {
                      setHce(3);
                    }}
                  >
                    CUSTOMER <br /> OUTCOMES
                  </h3>
                </ScrollAnimation>
              </div>
              {hce == 1 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3>OVERVIEW:</h3>
                  <p>
                    Modern applications are resident, multi-cloud supportive
                    software service comprised of orchestrated releases of
                    containers, virtual machines, and serverless functions. The
                    ability to deliver modern apps It is defined by an IT
                    organisation that can modernise business -critical
                    applications, rapidly deploy, run, and scale
                    business-critical applications and provide edge computing
                    for application and data consumption proximity.
                  </p>
                </div>
              ) : (
                <></>
              )}
              {hce == 2 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                    CHALLENGES:
                  </h3>
                  <p>
                    • Vendor locked-in silos and business application complexity{" "}
                    <br />
                    • IT forced to learn new tools to create, manage and monitor
                    big data workloads <br />
                    • Complex processes and multiple tools <br />
                  </p>
                </div>
              ) : (
                <></>
              )}
              {hce == 3 ? (
                <div id="cloud-comp-sub-2" className="fade-intro">
                  <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                    CUSTOMER OUTCOMES:
                  </h3>
                  <p>
                    • Helping to generate new forms of revenue <br />
                    • Respond quickly to market change <br />
                    • A unified experience across multiple cloud platforms,
                    leveraging the existing knowledge and adapting to support
                    multi-cloud <br />
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </ScrollAnimation>
        </div>
      </section>
    </>
  );
}
