import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";
import privateHybridCloud from "../components/cloud-services/privateHybridCloud";

//Images
import cloud1 from "../images/cloud/w-cloud.png";
import laptop1 from "../images/cloud/w-laptop.png";
import gear1 from "../images/cloud/w-gear.png";
import cloud2 from "../images/cloud/b-cloud.png";
import laptop2 from "../images/cloud/b-laptop.png";
import gear2 from "../images/cloud/b-gear.png";

import ScrollAnimation from "react-animate-on-scroll";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import "../styles/animations.scss";
import ContactForm from "../components/ContactForm";
import orchestrationAutomation from "../components/cloud-services/orchestrationAutomation";
import digitalEnterprise from "../components/cloud-services/digitalEnterprise";
import SEO from "../components/seo";
// import { img } from 'gatsby-plugin-image';

// markup
const CloudServicesPage = () => {
  const [activeService, setActiveService] = React.useState(1);

  const props = {
    seoTitle: "Cloud Services",
    seoDescription:
      "Strategix Technology Solutions’ cloud services allow you to build, deliver and manage effective multicloud operations on verified enterprise grade cloud.",

    class1: activeService == 1 ? "cloud-tab-active" : "cloud-tab",
    class2: activeService == 2 ? "cloud-tab-active" : "cloud-tab",
    class3: activeService == 3 ? "cloud-tab-active" : "cloud-tab",
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // window.addEventListener("onclick", function handleClick(event) {
      //   console.log(window.location.pathname);
      //   console.log("click");
      // });

      window.addEventListener("click", function (event) {
        if (window.location.hash == "#private-and-hybrid-cloud") {
          // console.log("engage meth");
          setActiveService(1);
        }
        if (window.location.hash == "#orchestration-and-automation") {
          // console.log("vmware professional services");
          setActiveService(2);
        }
        if (window.location.hash == "#digital-enterprise") {
          // console.log("strategic el");
          setActiveService(3);
        }
      });
    }
  }, []);

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="cloudserv">
            <div className="flex-col-c">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <div id="long-section-text" className="flex-col-c">
                  <h1>Cloud Services</h1>
                  <p>
                    Build, deliver and manage effective multicloud operations.
                  </p>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <div id="long-section-statement">
                  <div className="w-max">
                    <h2>
                      Our trusted delivery teams allow you to manage multicloud
                      operations, seamlessly automate workflows and modernise
                      business applications.
                    </h2>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        <section
          style={{
            backgroundColor: "var(--grey)",
            minHeight: "20vh",
            paddingBottom: "0",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "2rem",
                  paddingTop: "5rem",
                  margin: "0",
                }}
              >
                Our Range of Cloud Services
              </h1>
            </ScrollAnimation>
            <div className="cloud-tab-wrap">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                <div
                  className={props.class1}
                  onClick={() => {
                    setActiveService(1);
                  }}
                >
                  <img
                    alt=""
                    className="cloud-icon"
                    src={activeService == 1 ? cloud2 : cloud1}
                  />
                  <h1>
                    Private and <br /> Hybrid Cloud
                  </h1>
                  <p>
                    Enable private cloud and expand hybrid cloud, and monitor
                    and manage software-based IT infrastructure.
                  </p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <div
                  className={props.class2}
                  onClick={() => {
                    setActiveService(2);
                  }}
                >
                  <img
                    alt=""
                    className="cloud-icon"
                    id="orch"
                    src={activeService == 2 ? gear2 : gear1}
                  />
                  <h1>
                    Orchestration and <br /> Automation
                  </h1>
                  <p>
                    Seamlessly provision and manage workflows in your platform
                    and infrastructure services and unlock business agility.
                  </p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={300}
              >
                <div
                  className={props.class3}
                  onClick={() => {
                    setActiveService(3);
                  }}
                >
                  <img
                    alt=""
                    className="cloud-icon"
                    src={activeService == 3 ? laptop2 : laptop1}
                  />
                  <h1>
                    Digital <br /> Enterprise
                  </h1>
                  <p>
                    Modernise business-critical applications, streamline the
                    development lifecycle and support distributed workforces.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        <a id="private-and-hybrid-cloud" />
        <a id="orchestration-and-automation" />
        <a id="digital-enterprise" />

        {activeService == 1 ? privateHybridCloud() : <></>}
        {activeService == 2 ? orchestrationAutomation() : <></>}
        {activeService == 3 ? digitalEnterprise() : <></>}

        {/* Partners */}
        <section style={{ minHeight: "50vh" }}>
          <PartnerCarousel />
        </section>

        {/* Contact */}
        <section className="half">
          <ContactForm />
        </section>
      </main>
    </Layout>
  );
};

export default CloudServicesPage;
