import React, { useState } from "react";

//Images
import img1 from "../../images/oa-cloud1.png";
import img2 from "../../images/oa-cloud2.png";
import img3 from "../../images/oa-cloud3.png";

import "./cloud-components.scss";
import "../../styles/animations.scss";
import { Link } from "gatsby";

import ScrollAnimation from "react-animate-on-scroll";

export default function orchestrationAutomation() {
  const [virtInf, setVirtInf] = useState(1);
  const [pce, setPce] = useState(1);
  const [hce, setHce] = useState(1);

  const props = {
    o1: virtInf == 1 ? "active" : "",
    c1: virtInf == 2 ? "active" : "",
    co1: virtInf == 3 ? "active" : "",

    o2: pce == 1 ? "active" : "",
    c2: pce == 2 ? "active" : "",
    co2: pce == 3 ? "active" : "",

    o3: hce == 1 ? "active" : "",
    c3: hce == 2 ? "active" : "",
    co3: hce == 3 ? "active" : "",
  };

  return (
    <>
      <div className="cloud-component-wrapper">
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h1>Orchestration and Automation</h1>
            <p>
              Manage automated tasks and create dynamic intelligence-driven
              workflows.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-image-packet">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="cloud-component-image-wrapper">
                <Link to="#iaas">
                  <img src={img1} className="image-fit fade-intro" />
                </Link>
                <h2>IAAS</h2>
                <p>Manage and provision infrastructure services.</p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={0.75}
              delay={100}
            >
              <div className="cloud-component-image-wrapper">
                <Link to="#paas">
                  <img src={img2} className="image-fit fade-intro" />
                </Link>
                <h2>PAAS</h2>
                <p>Manage and provision platform services.</p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={0.75}
              delay={200}
            >
              <div className="cloud-component-image-wrapper">
                <Link to="#intelligence-driven-solutions">
                  <img src={img3} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Intelligence <br /> Driven Solutions
                </h2>
                <p>Unlock business agility and innovation.</p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <section
        id="iaas"
        className="half"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
            >
              Infrastructure as a Service (IaaS)
            </h2>
            <p style={{ textAlign: "center" }}>
              Manage and provision technology services.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-sub-wrap" style={{ padding: "20px" }}>
            <div id="cloud-comp-sub">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h3
                  id="red-diam"
                  className={props.o1}
                  onClick={() => {
                    setVirtInf(1);
                  }}
                >
                  OVERVIEW
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                {" "}
                <h3
                  id="black-diam"
                  className={props.c1}
                  onClick={() => {
                    setVirtInf(2);
                  }}
                >
                  CHALLENGES
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <h3
                  id="blue-diam"
                  className={props.co1}
                  onClick={() => {
                    setVirtInf(3);
                  }}
                >
                  CUSTOMER <br /> OUTCOMES
                </h3>
              </ScrollAnimation>
            </div>
            {virtInf == 1 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3>OVERVIEW:</h3>
                <p>
                  Infrastructure-as-a-Service refers to instant infrastructure
                  services that are provisioned and managed by the customer. The
                  ability to deliver Infrastructure-as-a-Service is defined by
                  an IT organisation that can deliver standardised
                  infrastructure stacks on demand, provide self-service
                  consumption for business users and understand and control
                  costs.
                </p>
              </div>
            ) : (
              <></>
            )}
            {virtInf == 2 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                  CHALLENGES:
                </h3>
                <p>
                  • Long reaction times for business requests
                  <br />
                  • Inefficient resource allocation
                  <br />
                  • Poor capacity planning <br />
                  • High IT spend <br />
                  • Disaggregated information <br />
                </p>
              </div>
            ) : (
              <></>
            )}
            {virtInf == 3 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                  CUSTOMER OUTCOMES:
                </h3>
                <p>
                  • Shorter reaction time to business requests <br />
                  • Optimised growth and resource allocation <br />
                  • Better capacity planning <br />
                  • Reduced IT spending <br />
                  • Business relevant dashboards <br />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <section
        id="paas"
        className="half"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
            >
              Platform as a Service (PaaS)
            </h2>
            <p style={{ textAlign: "center" }}>
              Manage and provision platform services.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-sub-wrap" style={{ padding: "20px" }}>
            <div id="cloud-comp-sub">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h3
                  id="red-diam"
                  className={props.o2}
                  onClick={() => {
                    setPce(1);
                  }}
                >
                  OVERVIEW
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                {" "}
                <h3
                  id="black-diam"
                  className={props.c2}
                  onClick={() => {
                    setPce(2);
                  }}
                >
                  CHALLENGES
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <h3
                  id="blue-diam"
                  className={props.co2}
                  onClick={() => {
                    setPce(3);
                  }}
                >
                  CUSTOMER <br /> OUTCOMES
                </h3>
              </ScrollAnimation>
            </div>
            {pce == 1 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3>OVERVIEW:</h3>
                <p>
                  Platform-as-a-Service refers to instant platform services that
                  are provisioned and managed by the consumer. The ability to
                  deliver Platform-as-a-Service is defined by an IT organisation
                  that can deliver standardised application stacks on-demand,
                  deliver containers and functions on-demand, enable
                  configuration management for application platforms and
                  feedback loops with iterative platform development.
                </p>
              </div>
            ) : (
              <></>
            )}
            {pce == 2 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                  CHALLENGES:
                </h3>
                <p>
                  • Application teams working in silos <br />
                  • Application deployments are not standardised <br />
                  • Lack of unified catalog for applications and services <br />
                  • Developers unable to replicate problematic environments{" "}
                  <br />
                  • Configuration drifts causing unexpected downtime <br />
                  • High OPEX for application delivery <br />
                </p>
              </div>
            ) : (
              <></>
            )}
            {pce == 3 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                  CUSTOMER OUTCOMES:
                </h3>
                <p>
                  • A unified service portal for users to access applications,
                  infrastructure and platforms on demand <br />
                  • Reduce human errors and risks from manual
                  process/configuration of applications and systems <br />
                  • Being perceived as business partner for new solutions and
                  rapid business development <br />
                  • Higher trust <br />
                  • Increased quality <br />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <section
        id="intelligence-driven-solutions"
        className="half"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
            >
              Intelligence Driven Solutions
            </h2>
            <p style={{ textAlign: "center" }}>
              Unlock business agility and innovation.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-sub-wrap" style={{ padding: "20px" }}>
            <div id="cloud-comp-sub">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h3
                  id="red-diam"
                  className={props.o3}
                  onClick={() => {
                    setHce(1);
                  }}
                >
                  OVERVIEW
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                {" "}
                <h3
                  id="black-diam"
                  className={props.c3}
                  onClick={() => {
                    setHce(2);
                  }}
                >
                  CHALLENGES
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <h3
                  id="blue-diam"
                  className={props.co3}
                  onClick={() => {
                    setHce(3);
                  }}
                >
                  CUSTOMER <br /> OUTCOMES
                </h3>
              </ScrollAnimation>
            </div>
            {hce == 1 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3>OVERVIEW:</h3>
                <p>
                  Intelligence driven services are instant business services
                  that are provisioned and managed by the consumer. The ability
                  to deliver Intelligent Service is defined by an IT
                  organisation that can onboard and provision tenant services,
                  manage and govern the tenant service lifecycle and deliver
                  standardised business processes on-demand.
                </p>
              </div>
            ) : (
              <></>
            )}
            {hce == 2 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                  CHALLENGES:
                </h3>
                <p>
                  • Model any release process for software <br />
                  • Complex application and development lifecycles <br />
                </p>
              </div>
            ) : (
              <></>
            )}
            {hce == 3 ? (
              <div id="cloud-comp-sub-2" className=" fade-intro">
                <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                  CUSTOMER OUTCOMES:
                </h3>
                <p>
                  • Unified platform with integrated service lifecycle <br />•
                  Business will be enabled for rapid application development{" "}
                  <br />
                  • Continuous delivery approach enables business agility <br />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
