import React, { useState } from "react";

//Images
import img1 from "../../images/ph-cloud1.png";
import img2 from "../../images/ph-cloud2.png";
import img3 from "../../images/ph-cloud3.png";

import "./cloud-components.scss";

import "../../styles/animations.scss";
import { Link } from "gatsby";

import ScrollAnimation from "react-animate-on-scroll";

export default function privateHybridCloud() {
  const [virtInf, setVirtInf] = useState(1);
  const [pce, setPce] = useState(1);
  const [hce, setHce] = useState(1);

  const props = {
    o1: virtInf == 1 ? "active" : "",
    c1: virtInf == 2 ? "active" : "",
    co1: virtInf == 3 ? "active" : "",

    o2: pce == 1 ? "active" : "",
    c2: pce == 2 ? "active" : "",
    co2: pce == 3 ? "active" : "",

    o3: hce == 1 ? "active" : "",
    c3: hce == 2 ? "active" : "",
    co3: hce == 3 ? "active" : "",
  };

  return (
    <>
      <div className="cloud-component-wrapper">
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h1>Private and Hybrid Cloud</h1>
            <p>Monitor and manage software-based IT infrastructure.</p>
          </ScrollAnimation>
          <div className="cloud-component-image-packet">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="cloud-component-image-wrapper">
                <Link to="#virtualised-infrastructure">
                  <img src={img1} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Virtualised <br /> Infrastructure
                </h2>
                <p>Enabling software-based IT infrastructure.</p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={0.75}
              delay={100}
            >
              <div className="cloud-component-image-wrapper">
                <Link to="#private-cloud-enablement">
                  <img src={img2} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Private Cloud <br /> Enablement
                </h2>
                <p>Automated workload provisioning and self service.</p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={0.75}
              delay={200}
            >
              <div className="cloud-component-image-wrapper">
                <Link to="#hybrid-cloud-expansion">
                  <img src={img3} className="image-fit fade-intro" />
                </Link>
                <h2>
                  Hybrid Cloud <br /> Expansion
                </h2>
                <p>
                  Application migration, workload mobility and cloud-native
                  services.
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <section
        className="half"
        id="virtualised-infrastructure"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
            >
              Virtualised Infrastructure
            </h2>
            <p style={{ textAlign: "center" }}>
              Enabling software-based IT infrastructure.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-sub-wrap" style={{ padding: "20px" }}>
            <div id="cloud-comp-sub">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h3
                  id="red-diam"
                  className={props.o1}
                  onClick={() => {
                    setVirtInf(1);
                  }}
                >
                  OVERVIEW
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                <h3
                  id="black-diam"
                  className={props.c1}
                  onClick={() => {
                    setVirtInf(2);
                  }}
                >
                  CHALLENGES
                </h3>
              </ScrollAnimation>{" "}
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <h3
                  id="blue-diam"
                  className={props.co1}
                  onClick={() => {
                    setVirtInf(3);
                  }}
                >
                  CUSTOMER <br /> OUTCOMES
                </h3>
              </ScrollAnimation>
            </div>
            {virtInf == 1 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3>OVERVIEW:</h3>
                <p>
                  Virtual infrastructure is a software-based IT infrastructure
                  hosted on physical infrastructure. It is defined by an IT
                  organisation that has developed the ability to abstract and
                  pool compute and storage resources, automatically recover from
                  hardware failures and monitor and manage infrastructure
                  performance and capacity.
                </p>
              </div>
            ) : (
              <></>
            )}
            {virtInf == 2 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                  CHALLENGES:
                </h3>
                <p>
                  • Underutilised servers <br />
                  • High CAPEX for hardware, high OPEX for infrastructure <br />
                  • Lack of workload standardisation <br />
                  • High human error rate <br />
                  • Poor infrastructure availability <br />
                  • Insufficient data center/facility space <br />
                </p>
              </div>
            ) : (
              <></>
            )}
            {virtInf == 3 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                  CUSTOMER OUTCOMES:
                </h3>
                <p>
                  • Hardware consolidation and reduced data-center footprint{" "}
                  <br />
                  • Optimised power and cooling <br />• Unified management
                  interface and centralised dashboard. <br />
                  • High availability and reduced delivery time <br />
                  • Optimised resource allocation and accurate demand/capacity
                  forecasting <br />
                  • Improved detection and resolution rates of
                  infrastructure-related incidents <br />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      {/* Private Cloud Enablement */}
      <section
        className="half"
        id="private-cloud-enablement"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
            >
              Private Cloud Enablement
            </h2>
            <p style={{ textAlign: "center" }}>
              Automated workload provisioning and self service.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-sub-wrap" style={{ padding: "20px" }}>
            <div id="cloud-comp-sub">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h3
                  id="red-diam"
                  className={props.o2}
                  onClick={() => {
                    setPce(1);
                  }}
                >
                  OVERVIEW
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                <h3
                  id="black-diam"
                  className={props.c2}
                  onClick={() => {
                    setPce(2);
                  }}
                >
                  CHALLENGES
                </h3>
              </ScrollAnimation>{" "}
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <h3
                  id="blue-diam"
                  className={props.co2}
                  onClick={() => {
                    setPce(3);
                  }}
                >
                  CUSTOMER <br /> OUTCOMES
                </h3>
              </ScrollAnimation>
            </div>
            {pce == 1 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3>OVERVIEW:</h3>
                <p>
                  Private cloud is a customer or third-party managed on or
                  off-premises software-defined IT infrastructure. It is defined
                  by an IT organisation that can automate workload provisioning,
                  provide a self-service portal for IT consumers and provide
                  resiliency for business applications.
                </p>
              </div>
            ) : (
              <></>
            )}
            {pce == 2 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                  CHALLENGES:
                </h3>
                <p>
                  • Unplanned workload failures <br />
                  • Business critical failures lead to unplanned downtime <br />
                  • Long workload delivery times <br />
                  • Perception of slow IT reaction times <br />
                  • Organisational resistance to change <br />
                  • Poor IT financial visibility. <br />
                </p>
              </div>
            ) : (
              <></>
            )}
            {pce == 3 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                  CUSTOMER OUTCOMES:
                </h3>
                <p>
                  • Improved resource utilisation <br />
                  • Reduced costs <br />
                  • Flexibility <br />
                  • Enhanced security and control <br />
                  • Data sovereignty and compliance <br />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <section
        className="half"
        id="hybrid-cloud-expansion"
        style={{
          backgroundColor: "var(--grey)",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h2
              style={{ textAlign: "center", paddingTop: "5rem", margin: "0" }}
            >
              Hybrid Cloud Expansion
            </h2>
            <p style={{ textAlign: "center" }}>
              Application migration, workload mobility and cloud-native
              services.
            </p>
          </ScrollAnimation>
          <div className="cloud-component-sub-wrap" style={{ padding: "20px" }}>
            <div id="cloud-comp-sub">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h3
                  id="red-diam"
                  className={props.o3}
                  onClick={() => {
                    setHce(1);
                  }}
                >
                  OVERVIEW
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={100}
              >
                <h3
                  id="black-diam"
                  className={props.c3}
                  onClick={() => {
                    setHce(2);
                  }}
                >
                  CHALLENGES
                </h3>
              </ScrollAnimation>{" "}
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={0.75}
                delay={200}
              >
                <h3
                  id="blue-diam"
                  className={props.co3}
                  onClick={() => {
                    setHce(3);
                  }}
                >
                  CUSTOMER <br /> OUTCOMES
                </h3>
              </ScrollAnimation>
            </div>
            {hce == 1 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3>OVERVIEW:</h3>
                <p>
                  Hybrid cloud entails customer and third-party clouds connected
                  using standardised or proprietary technology. It is defined by
                  an IT organisation that can enable application migration and
                  workload mobility and provide access to cloud-native services.
                </p>
              </div>
            ) : (
              <></>
            )}
            {hce == 2 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3 style={{ backgroundColor: "var(--black)) !important" }}>
                  CHALLENGES:
                </h3>
                <p>
                  • Unexpected resource demand in a specific timeframe <br />
                  • Shadow IT <br />
                  • Virtual machine sprawl <br />• Unplanned workload placement
                  has unexpected performance impact <br />
                  • Unable to accurately track service levels <br />
                </p>
              </div>
            ) : (
              <></>
            )}
            {hce == 3 ? (
              <div id="cloud-comp-sub-2" className="fade-intro">
                <h3 style={{ backgroundColor: "var(--blue) !important" }}>
                  CUSTOMER OUTCOMES:
                </h3>
                <p>
                  • Shorter reaction time to business requests <br />
                  • Optimised growth and resource allocation <br />
                  • Better capacity planning <br />
                  • Reduced IT spending <br />
                  • Business relevant dashboards <br />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
